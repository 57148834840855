body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading{
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading::after{
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: rgb(79, 172, 201);
  border-bottom-color: rgba(184, 181, 181, 0.877);
  border-radius: 50%;
  -webkit-animation: loading 1s linear infinite!important;
          animation: loading 1s linear infinite!important;
}
@-webkit-keyframes loading {
  to{
      -webkit-transform:rotate(1turn);
              transform:rotate(1turn);
  }
}
@keyframes loading {
  to{
      -webkit-transform:rotate(1turn);
              transform:rotate(1turn);
  }
}

*{
  margin:0;
  padding:0;
}

:root{
  --blackColor: #555454;
  --cursiveFamily:'Kaushan Script', cursive;
  --headingFamily:'Open Sans', sans-serif;
  --textFamily:'Lato', sans-serif;
  --purpleColor:#be0bce;
  --primaryColor: rgb(79, 172, 201);
  --backgroundColor: rgb(238, 239, 248);
}
body{
  width: 100%;
  height:auto!important;
  overflow-y: scroll!important;
}
.animate-character{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #4fbbc9 0%,
    #1058aaf3 29%,
    #d8d6d6 67%,
    #0099ffa2 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: textclip 4s linear infinite;
          animation: textclip 4s linear infinite;
  display: inline-block;
}

@-webkit-keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* another animation */


.home-banner{
    background: linear-gradient(190deg, rgba(39, 129, 136, 0.096) 30%, rgba(10, 10, 58, 0.096) 50%), url(/static/media/inspiredbg.99d601eb.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 350px;
    position: relative;
    padding: 30px 0px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    
   
}
.banner-text{
    margin-right: 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0px 80px;
}
@media screen and (max-width: 840px ) {
    .home-banner{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content
    }
    .banner-text{
        flex-direction: column-reverse;
        grid-gap: 30px;
        gap: 30px;
    }
    .bannerImage{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 80%;
    }
    .bannerImage img{
        width: 80%!important
    }
}
.banner-content{
    flex:9 1;
}
@media screen and (max-width: 500px ) {
    .banner-text{
        padding: 0 20px;
    }
    .banner-content{
        flex: 12 1;
    }
}
.bannerImage{
    height: 300px;
    object-fit: cover;
    padding: 2px;
    flex:3 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 1050px ) {
    .banner-content{
        flex: 8 1
    }
    .bannerImage{
        flex:4 1
    }
}
.bannerImage img{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    object-fit: contain;
    border-radius: 10px!important;
    border: 5mm  ridge var(--primaryColor)
    
}

.home-banner .welcome{
    background: var(--primaryColor);
    color: #fff;
    font-family: var(--textFamily);
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    padding: 4px 10px;
    letter-spacing: 1px;
    margin-bottom: 20px;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto
}
.home-banner h1{
    font-weight: 900;
    padding-bottom: 10px;
    font-family: var(--textFamily);
    color:#fff;
    display:flex;
    font-size:60px;
    font-weight:900;
    flex-flow:row wrap;
    align-content:center;
    justify-content:center;
    margin: 20px 0px;
}

.home-banner p{
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}
.home-content{
    background: #fff;
    height: auto;
    padding-bottom: 40px;
    width: 100%;
}
/* forum container starts here */
.forumContainer{
    margin: 30px 0px 20px;
    background: var(--primaryColor);
    height: 100px;
    padding: 10px;
    color:#fff;
    font-family: var(--textFamily);
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.forumContainer .left{
    flex: 1 1;
    font-size: xx-large;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    width: 100%;
    height: 100%;
}
.forumContainer .left .text{
    position: absolute;
    top: 20%;
    left: 2%;
    /* bottom: 50%; */
    /* transform: translate(-50%, -50%); */
}
@media screen and (max-width: 993px ) {
    .forumContainer{
        flex-direction: column;
        justify-content: center;
    }
    .forumContainer .left .text{
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom:0px;
        margin:auto;
        text-align: center;
    }
}
@media screen and (max-width: 490px) {
    .forumContainer .left{
        font-size: large;
        text-align: center;
    }
}
.forumContainer .left .text:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;

}
  
.forumContainer .left .text:nth-child(2) {
    color: #03a9f4;
    -webkit-animation: wateranimate 4s ease-in-out infinite;
            animation: wateranimate 4s ease-in-out infinite;
}
@-webkit-keyframes wateranimate {
    0%,
    100% {
      -webkit-clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      -webkit-clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
}
@keyframes wateranimate {
    0%,
    100% {
      -webkit-clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      -webkit-clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
}
.forumContainer .right{
    font-size: x-large;
    font-weight: 600;
}


/* serach session here */
.searchContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchContainer .searchWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 45px;
    position: relative;
}
@media screen and (max-width: 780px ) {
    .searchContainer .searchWrapper{
        width: 70%;
    }
}
.searchContainer .searchWrapper input{
    width: 100%;
    height: 100%;
    border: 1px solid rgba(156, 153, 153, 0.521);
    border-radius: 5px;
    background: rgba(226, 222, 222, 0.233);
    padding: 5px 10px;
}
.searchContainer .searchWrapper input:focus{
    outline: none;
    border: 1px solid var(--primaryColor);
    color: var(--blackColor)
}
.searchContainer .searchWrapper .searchBtn{
    position: absolute;
    right: 0px;
    width: 45px;
    height: 100%;
    background: var(--primaryColor);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.searchContainer .searchWrapper .searchBtn .icon{
    color: #fff;
    font-size: x-large;
}
/* home catgeory session ends here */


.display-posts{
    padding: 40px 0px;
}
.post-row{
    width: 92%;
    font-family: var(--textFamily);
}
.post-row .top{
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}
.posterby{
    font-size: 17px;
    color:#777575;
    font-weight: 600;
    font-family: var(--textFamily);
}
.top .date{
    color: rgba(172, 169, 169, 0.719);
    font-size: 14px;
}
.post-container{
    display: flex;
    height:auto;
    background:rgba(231, 231, 236, 0.712);
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.4s all ease;
    align-items: flex-start;
}
.post-container:hover{
    background: rgba(65, 162, 192, 0.164);
}
.post-container img{
    width: 100px;
    height: 100%;
}
.post-container img{
    width: 10%;
    height: auto;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.post-container .other-content{
    padding-left: 15px;
}
.blogpost-nav{
   text-decoration: none;
}
.blogpost-nav .post-topic{
    font-size: 15px;
    font-weight:500;
    text-transform: capitalize;
    padding:2px 0px 10px 0px;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color:rgba(97, 96, 96, 0.842);
    font-weight: 600
}
.other-content .category{
    color: #777575;
    font-size: 15px;
    text-transform: capitalize;
}
.other-content a{
    text-decoration: none;
    color:#333;
    font-size: 15px;
    font-weight: 600;
    transition: 0.4s all ease;
}
.other-content a span{
    color: #777575;
    font-weight: normal
}
.other-content a:hover{
    color: var(--primaryColor);
}

/* home category strarts here */
.home-category{
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.home-category .category{
    background: #fff;
    box-shadow: 0px 10.8889px 37.3333px rgba(127, 147, 183, 0.19);
    border-radius: 4;
    font-size: 12px;
    padding: 10px 15px;
    color: var(--blackColor);
    cursor: pointer;
    font-family: var(--textFamily);
    text-transform: uppercase;
    font-weight: 600;
}
.home-category .categoryactive{
    background: var(--primaryColor)!important;
    color:#fff;
}
/* .home-btn{
    border-right: none;
    border-left: none;
    padding: 10px;
    background: #fff;
    border: 1px solid rgba(221, 220, 220, 0.555);
    text-align: left;
}
.activeclass .home-btn:hover{
    background: var(--blackColor)!important;
    color:#fff!important
} */
.home-category .text{
    font-size: 14px;
    text-transform: uppercase;
    color:var(--primaryColor);
    font-weight: 600;
    padding: 20px 0px;
    padding-left: 5px;
}

/* css for pagination */
.paginationBttns{
    width: 100%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 30px 10px;
    /* margin-top: 30px; */
}
.paginationBttns a{
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    border:1px solid var(--mainBlue);
    color: #fff!important;
    cursor:pointer;
    transition:0.4s all ease ;
    text-decoration: none;
    background: #afadad;
}
.paginationBttns a:hover{
    color:#fff!important;
    background: var(--primaryColor)
}
.paginationActive a{
    background: var(--primaryColor);
    color:#fff!important;
}
@media screen and (max-width: 760px){
    .post-row{
        width: 100%;
        font-family: var(--textFamily);
    }
    .post-row .top{
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
    }
    .posterby{
        font-size: 14px;
        
    }
    .top .date{
        color: rgba(172, 169, 169, 0.719);
        font-size: 12px;
    }
    .post-container{
        display: flex;
        height: auto;
    }
   
    .post-container img{
        width: 23%;
        height: auto;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .post-container .other-content{
        padding-left: 15px;
        text-align: left;
    }
    .blogpost-nav .post-topic{
        font-size: 13px;
      
    }
    .other-content .category{
        color: #777575;
        font-size: 12px;
    }
    .other-content a{
        text-decoration: none;
        color:#333;
        font-size: 12px;
    }
    .home-banner h1{
        font-size: 43px;
        font-weight: 900;
        padding-bottom: 30px;
        padding-top: 20px;
    }
   
}
.form-input{
    border: 1px solid var(--primaryColor);
    padding: 30px 10px ;
    box-shadow: 10px 15px var(--primaryColor);
    border-radius: 5px;

    max-width: 550px;
    margin: 40px auto;
    padding: 20px 20px;
    text-transform: uppercase;
}
.auth-header{
    text-align: center;
    padding-bottom: 30px;
    text-transform: uppercase;
    font-family: var(--textFamily);
    font-weight: 700;
    font-size: 18px;
}
.locked-div{
    text-align: center;
    align-items: center;
    margin-bottom: 15px;
}
.locked{
    background: var(--primaryColor);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 12px;
    text-align: center;
}

.inputfield{
    width: 100%;
    height: 50px;
    outline: none;
    border:1px solid #333;
    background: #fff;
    padding-left: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
}
label{
    font-size: 15px;
    font-weight: 600;
}
.inputfield:focus{
    border: 1px solid var(--primaryColor);
}
.submit-div{
    text-align: right;
    align-items: right;
    width: 100%;
}
.submitButton{
    padding: 10px 20px;
    background: var(--primaryColor);
    color:#fff;
    font-size: 16px;
    border: none;
    transition: 0.4s all ease;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    border-radius: 5px;
    right: 0;
    width: 100%;
}
.submitButton:hover{
    background:rgb(83, 147, 167);
}
.passwordshow{
    display: flex;
    position: relative;
}
.show-icon{
    position: absolute;
    right: 7px;
    font-size: 25px;
    top: 15px;
    cursor: pointer;
    color:rgb(160, 160, 160);
    transition: 0.4s all ease;
}
.show-icon:hover{
    color:rgb(133, 131, 131);
}
.btn-div{
    text-align: center;
    align-items: center;
}
.button-text{
    border: none;
    padding-top: 30px;
    font-family: var(--textFamily);
    background: none;
    text-align: center;
}
.singlepost-container{
    background: var(--backgroundColor);
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 50px 0px;
    color:#333;
    font-family: var(--headingFamily)
}
.singlepost-content{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background: #fff;
    border-radius: 10px;
    /*  */
}

.single-nav{
    text-align: center;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--headingFamily);
    font-weight: bold;
    text-transform: uppercase;
    font-size: x-large;
    text-decoration: none;
    transition: 0.3s all ease;
    position: relative;
    background: rgba(231, 231, 236, 0.712);
}
.single-nav:hover{
    background:rgba(199, 199, 202, 0.712);
}
.single-nav .single-header {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: xx-large;
    /* bottom: 50%; */
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.single-nav .single-header:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 3px var(--primaryColor);

}
  
.single-nav .single-header:nth-child(2) {
    color: rgb(71, 5, 5);
    -webkit-animation: singlewateranimate 4s ease-in-out infinite;
            animation: singlewateranimate 4s ease-in-out infinite;
}
@-webkit-keyframes singlewateranimate {
    0%,
    100% {
      -webkit-clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      -webkit-clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
}
@keyframes singlewateranimate {
    0%,
    100% {
      -webkit-clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      -webkit-clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
              clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
}

.title-middle{
    margin: 30px 0px;
    text-align: center;
}
.title-middle .topic{
    font-size: 22px;
    font-family: var(--headingFamily);
    color:rgb(71, 5, 5);
    text-transform: capitalize;
    font-weight: 700;
    padding-bottom: 10px
}
.title-middle .sub-link .Link{
    text-decoration: none;
    color:rgb(116, 39, 39)!important;
    font-size: 15px;
    transition: 0.3s all ease;
}
.title-middle .sub-link .Link:hover{
    color: rgb(71, 5, 5);
    text-decoration: underline;
}
.title-middle .comment{
    font-size: 15px;
    color:rgb(71, 5, 5);
}

.singlepost-top{
    background: rgba(231, 231, 236, 0.712);
    border-bottom: 1px solid var(--backgroundColor);
    padding: 5px 10px;
    color: var(--primaryColor);
    font-size: 16px;
    text-transform: capitalize;
}
.singlepost-top .by{
    color:#333;
    text-transform: lowercase;
    font-size: 15px;
}
.singlepost-top .poster{
    color:rgb(71, 5, 5);
    font-size: 15px;
    font-weight: 600;
}
.singlepost-top .colon{
    color:#333;
}
.singlepost-top .time{
    font-size: 15px;
    color:rgb(87, 78, 80)
}
.singlepost-top .on{
    font-size: 15px ;
    color:#333;
}

.singlepost-content p{
    font-style: italic;
}
.singlepost-content p span{
    font-style: normal;
    text-transform: capitalize;
}
.singlepost-content img{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.singlepost-other{
    padding: 20px 10px;
}
.singlepost-message p{
    font-size: 15px!important;
    font-style: normal!important;
}

@media screen and (max-width: 600px) {
    .title-middle .topic{
        font-size: 20px;
    }
    
}
.comment-form{
    width: 100%;
}
.comment-form p{
    font-size: 13px;
    color: red;
    padding-bottom: 10px;
}
.comment-textarea{
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(202, 200, 200, 0.705);
    margin: 5px 0px ;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    color: var(--blackColor)
}

.comment-input{
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(202, 200, 200, 0.705);
    margin: 5px 0px ;
    height: 40px;
    font-size: 14px;
    padding: 5px 10px;
    outline: none;
    color: var(--blackColor)
}
.comment-input:focus, .comment-textarea:focus{
    border: 1px solid var(--primaryColor)
}
.comment-btn{
    background: var(--primaryColor);
    border: none;
    color:#fff;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px 20px;
}
.comment-btn:hover{
    background: rgb(27, 147, 177);
}
.display-comments{
    margin: 10px;
    background: var(--backgroundColor);
    border-radius: 5px;
}
.display-comments .comment{
    padding: 10px 7px;
}
/* .display-comments h6{
    text-align: center;
    font-size: 16px;
} */
.display-comments .name-date{
    display: flex;
    justify-content: space-between;
    background:rgba(182, 184, 184, 0.164);
    padding: 5px 7px;
}
.display-comments .name{
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  
}
.display-comments .date{
    font-size: 14px;
    color:rgba(126, 126, 126, 0.404);
}
.display-comments .comment{
    font-size: 15px;
}
.CommentHeader{
    width: 100%;
    height: 70px;
    background: var(--primaryColor);
    border: 4mm ridge var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    margin-top: 20px;
    font-size: x-large;
    text-transform: capitalize;
    font-weight: 600;
}
.navbar {
    height: 100px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 15px 25px -15px var(--primaryColor);
    transition:0.5s all ease;
    z-index: 20;
    background: #e6e7e8;
    width: 100%;
}
.navbar.active{
    background: #e6e7e8;
    position: -webkit-sticky;
    position: fixed;
    top:0;
    width: 100%;
    height:100px;
    box-shadow: 0px 15px 25px -15px var(--backgroundColor);
    z-index: 99;
}
.navbar-container{
    display: flex;
    justify-content: space-between;
    width: 100%
}
.nav-nav{
    width: 7%;
    height: 90px;
}
.navbar-container img{
    width: 100%;
    height: 90px;
}
.logo-text{
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    padding-top: 20px;
    color:#333;
    font-family: var(--cursiveFamily);
}
.logo-text .date{
    font-family: var(--headingFamily);
    font-size: 13px;
    color:rgb(116, 115, 115);
}
.nav-btn{
    background: var(--primaryColor);
    border: none;
    padding: 10px 20px;border-radius: 3px;
    color: #fff;
    font-family: var(--textFamily);
    font-weight: 600;
    transition: 0.4s all ease;
}
.nav-btn:hover{
    background:rgb(83, 147, 167);
}
.nav-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-user{
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
}

.nav-user p{
    color: #333;
    font-size: 17px;
    font-weight: 550;
    font-family: var(--textFamily);
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {
    .nav-nav{
        width: 13%;
        height: 90px;
    }
    .navbar-container img{
        width: 100%;
        height: 90px;
    }
    
}
@media screen and (max-width: 500px) {
    .nav-nav{
        width: 20%;
        height: 90px;
    }
   
}
