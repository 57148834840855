.singlepost-container{
    background: var(--backgroundColor);
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding: 50px 0px;
    color:#333;
    font-family: var(--headingFamily)
}
.singlepost-content{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background: #fff;
    border-radius: 10px;
    /*  */
}

.single-nav{
    text-align: center;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--headingFamily);
    font-weight: bold;
    text-transform: uppercase;
    font-size: x-large;
    text-decoration: none;
    transition: 0.3s all ease;
    position: relative;
    background: rgba(231, 231, 236, 0.712);
}
.single-nav:hover{
    background:rgba(199, 199, 202, 0.712);
}
.single-nav .single-header {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: xx-large;
    /* bottom: 50%; */
    transform: translate(-50%, -50%);
}

.single-nav .single-header:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 3px var(--primaryColor);

}
  
.single-nav .single-header:nth-child(2) {
    color: rgb(71, 5, 5);
    animation: singlewateranimate 4s ease-in-out infinite;
}
@keyframes singlewateranimate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }
  
    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
}

.title-middle{
    margin: 30px 0px;
    text-align: center;
}
.title-middle .topic{
    font-size: 22px;
    font-family: var(--headingFamily);
    color:rgb(71, 5, 5);
    text-transform: capitalize;
    font-weight: 700;
    padding-bottom: 10px
}
.title-middle .sub-link .Link{
    text-decoration: none;
    color:rgb(116, 39, 39)!important;
    font-size: 15px;
    transition: 0.3s all ease;
}
.title-middle .sub-link .Link:hover{
    color: rgb(71, 5, 5);
    text-decoration: underline;
}
.title-middle .comment{
    font-size: 15px;
    color:rgb(71, 5, 5);
}

.singlepost-top{
    background: rgba(231, 231, 236, 0.712);
    border-bottom: 1px solid var(--backgroundColor);
    padding: 5px 10px;
    color: var(--primaryColor);
    font-size: 16px;
    text-transform: capitalize;
}
.singlepost-top .by{
    color:#333;
    text-transform: lowercase;
    font-size: 15px;
}
.singlepost-top .poster{
    color:rgb(71, 5, 5);
    font-size: 15px;
    font-weight: 600;
}
.singlepost-top .colon{
    color:#333;
}
.singlepost-top .time{
    font-size: 15px;
    color:rgb(87, 78, 80)
}
.singlepost-top .on{
    font-size: 15px ;
    color:#333;
}

.singlepost-content p{
    font-style: italic;
}
.singlepost-content p span{
    font-style: normal;
    text-transform: capitalize;
}
.singlepost-content img{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    height: fit-content;
}

.singlepost-other{
    padding: 20px 10px;
}
.singlepost-message p{
    font-size: 15px!important;
    font-style: normal!important;
}

@media screen and (max-width: 600px) {
    .title-middle .topic{
        font-size: 20px;
    }
    
}