.comment-form{
    width: 100%;
}
.comment-form p{
    font-size: 13px;
    color: red;
    padding-bottom: 10px;
}
.comment-textarea{
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(202, 200, 200, 0.705);
    margin: 5px 0px ;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    color: var(--blackColor)
}

.comment-input{
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(202, 200, 200, 0.705);
    margin: 5px 0px ;
    height: 40px;
    font-size: 14px;
    padding: 5px 10px;
    outline: none;
    color: var(--blackColor)
}
.comment-input:focus, .comment-textarea:focus{
    border: 1px solid var(--primaryColor)
}
.comment-btn{
    background: var(--primaryColor);
    border: none;
    color:#fff;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px 20px;
}
.comment-btn:hover{
    background: rgb(27, 147, 177);
}
.display-comments{
    margin: 10px;
    background: var(--backgroundColor);
    border-radius: 5px;
}
.display-comments .comment{
    padding: 10px 7px;
}
/* .display-comments h6{
    text-align: center;
    font-size: 16px;
} */
.display-comments .name-date{
    display: flex;
    justify-content: space-between;
    background:rgba(182, 184, 184, 0.164);
    padding: 5px 7px;
}
.display-comments .name{
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  
}
.display-comments .date{
    font-size: 14px;
    color:rgba(126, 126, 126, 0.404);
}
.display-comments .comment{
    font-size: 15px;
}
.CommentHeader{
    width: 100%;
    height: 70px;
    background: var(--primaryColor);
    border: 4mm ridge var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    margin-top: 20px;
    font-size: x-large;
    text-transform: capitalize;
    font-weight: 600;
}