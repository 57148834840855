.loading{
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading::after{
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid #dddddd;
  border-top-color: rgb(79, 172, 201);
  border-bottom-color: rgba(184, 181, 181, 0.877);
  border-radius: 50%;
  animation: loading 1s linear infinite!important;
}
@keyframes loading {
  to{
      transform:rotate(1turn);
  }
}

*{
  margin:0;
  padding:0;
}

:root{
  --blackColor: #555454;
  --cursiveFamily:'Kaushan Script', cursive;
  --headingFamily:'Open Sans', sans-serif;
  --textFamily:'Lato', sans-serif;
  --purpleColor:#be0bce;
  --primaryColor: rgb(79, 172, 201);
  --backgroundColor: rgb(238, 239, 248);
}
body{
  width: 100%;
  height:auto!important;
  overflow-y: scroll!important;
}
.animate-character{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #4fbbc9 0%,
    #1058aaf3 29%,
    #d8d6d6 67%,
    #0099ffa2 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* another animation */
